
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
const app = namespace("app");
const base = namespace("base");
const customer = namespace("customer");

@Component({
  inheritAttrs: false,
  model: {
    prop: "plateCode",
    event: "input"
  }
})
export default class InputCarNum extends Vue {
  @base.Action getDictionaryList;
  @customer.Action getCustomerList;
  @Prop({ default: "" })
  plateCode: string;
  @Prop({ default: 1 }) // 1：输入框 2：搜索下拉框
  mode: number;
  carNum: string = "";
  carNumList: any[] = [];
  selfValue: string = "";
  plateNum: string = "";
  plateCodeOptions: any[] = [];
  created() {
    this.init();
  }
  updateValue(val, type) {
    val = val.toUpperCase();
    if (type === "input") {
      this.$emit("input", this.$provinceValue + val);
    } else {
      this.$emit("input", val + this.$plateValue);
      this.$emit("change", val + this.plateNum);
    }
  }
  init() {
    this.getDictionaryList("plate_province").then(data => {
      this.carNumList = data;
      if (!this.$provinceValue && this.carNumList[0]) {
        this.$emit("input", this.carNumList[0].dataValue + this.$plateValue);
      }
    });
  }
  remoteMethod(val) {
    if (val) {
      this.getCustomerList({
        pageSize: 20,
        pageNum: 1,
        searchKey: this.$provinceValue + val
      }).then(data => {
        if (!data.data.list) {
          this.plateCodeOptions = [];
        } else {
          this.plateCodeOptions = data.data.list.map(item => {
            item.plateCode = item.plateCode.slice(1);
            return item;
          });
        }
      });
    } else {
      this.plateCodeOptions = [];
    }
  }
  handleChooseVehicle() {
    this.$emit("input", this.$provinceValue + this.plateNum);
    this.$emit("change", this.$provinceValue + this.plateNum);
  }
  get $provinceValue() {
    return this.plateCode ? this.plateCode.substr(0, 1) : "京";
  }
  get $plateValue() {
    return this.plateCode.slice(1);
  }
}
